<template>
  <v-container fluid>
    <v-slide-x-transition mode="out-in">
      <div
        v-if="selectedView === '1'"
        key="1"
        class="pa-5"
      >
        <v-flex class="mt-5">
          <h1 class="mb-6">
            Eshop items
          </h1>
          <v-spacer />
        </v-flex>
        <v-card elevation="0" rounded="xl">
          <v-card-title>
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="40"
              />
            </template>
            <v-text-field
              v-model="search"
              prepend-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
            <v-btn
              class="ml-15"
              dark
              color="green"
              @click="selectedView = '2'"
            >
              New Item
            </v-btn>
          </v-card-title>
          <v-data-table
            v-if="loaded"
            :headers="headers"
            :items="items"
            :search="search"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :footer-props="footerProps"
            :loading="showProgress"
            loading-text="Loading... Please wait"
          >
            <template v-slot:body="props">
              <draggable
                :list="props.items"
                tag="tbody"
                @change="endedSort"
              >
                <tr
                  v-for="item in props.items"
                  :key="item.index"
                >
                  <td>
                    <v-icon
                      small
                      class="page__grab-icon"
                    >
                      mdi-arrow-all
                    </v-icon>
                  </td>
                  <td> {{ item.index }} </td>
                  <td>
                    <v-checkbox
                      v-model="item.itemEnabled"
                      color="green"
                      :readonly="true"
                    />
                  </td>
                  <td>
                    <v-chip dark>
                      {{ item.itemSKU }}
                    </v-chip>
                  </td>
                  <td>
                    <div style="font-weight: bold;">
                      {{ item.itemName }}
                    </div>
                  </td>
                  <td>
                    <div v-if="item.itemPrice">
                      {{ item.itemPrice }}
                    </div>
                    <div v-else>
                      <v-icon color="red">
                        mdi-close
                      </v-icon>
                    </div>
                  </td>
                  <td>
                    <div v-if="item.subscriptionUpfrontPrice">
                      {{ item.subscriptionUpfrontPrice }}
                    </div>
                    <div v-else>
                      <v-icon color="red">
                        mdi-close
                      </v-icon>
                    </div>
                  </td>
                  <td>
                    <div v-if="item.subscriptionPriceMonth">
                      {{ item.subscriptionPriceMonth }}
                    </div>
                    <div v-else>
                      <v-icon color="red">
                        mdi-close
                      </v-icon>
                    </div>
                  </td>
                  <td>
                    <div v-if="item.memberSubscriptionPriceMonth">
                      {{ item.memberSubscriptionPriceMonth }}
                    </div>
                    <div v-else>
                      <v-icon color="red">
                        mdi-close
                      </v-icon>
                    </div>
                  </td>
                  <td>
                    <div class="p-2">
                      <v-img
                        :src="item.itemPicturesAWSLinks[0]"
                        width="40px"
                      />
                    </div>
                  </td>
                  <td>
                    <span>{{ item.qtyInStock }}</span>
                  </td>
                  <td>
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteDialog(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </draggable>
            </template>

            <template v-slot:top>
              <v-dialog
                v-model="dialogDelete"
                max-width="500px"
              >
                <v-card>
                  <v-card-title class="text-h5">
                    Delete this item?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeDelete"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="red darken-1"
                      text
                      @click="deleteItem"
                    >
                      DELETE
                    </v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <!--

            <template v-slot:item.itemPicture="{ item }">
              <div class="p-2">
                <v-img
                  :src="item.itemPicturesAWSLinks[0]"
                  width="40px"
                />
              </div>
            </template>
            <template v-slot:item.itemShortDescription="{ item }">
              <span>{{ trimText(item.itemShortDescription) }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteDialog(item)"
              >
                mdi-delete
              </v-icon>
            </template> -->
          </v-data-table>
        </v-card>
      </div>
      <div
        v-else-if="selectedView === '2'"
        key="2"
        class="pl-5 pr-5 bg"
      >
        <v-alert
          v-if="showError"
          style="position: absolute; top: 25px;; left: 50%; transform: translate(-50%);"
          type="error"
          dismissible
        >
          {{ errorMessage }}
        </v-alert>
        <v-row class="mt-5">
          <h1 class="mb-6">
            Add eshop item
          </h1>
          <v-spacer />
          <v-btn
            class="ml-15"
            dark
            color="orange"
            @click="selectedView = '1'; clearForm();"
          >
            Go back
          </v-btn>
        </v-row>
        <v-form
          ref="form"
        >
          <v-card elevation="3" class="mb-6">
            <v-card-title>
              Details - {{ itemId }}
            </v-card-title>
            <v-card-text>
              <v-checkbox
                v-model="itemEnabled"
                color="green"
                label="Item Enabled?"
              />
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="itemName"
                    :counter="30"
                    label="Item Name"
                    outlined
                    required
                  />
                </v-col>
              </v-row>
              <v-row class="mb-1" style="margin-top: -30px;">
                <v-col cols="3">
                  <v-text-field
                    v-model="itemSKU"
                    label="Item SKU"
                    type="text"
                    dense
                    outlined
                    required
                  />
                </v-col>
                <v-col cols="5">
                  <v-select
                    v-model="itemCategory"
                    style="max-width: 450px; display: inline-block;"
                    :items="itemCategoryOptions"
                    label="Item Category"
                    item-text="category"
                    dense
                    item-value="category"
                    outlined
                  />
                  <div style="display: inline-block; margin-left: 5px;">
                    <v-dialog
                      v-model="editCategoriesDialog"
                      scrollable
                      max-width="600px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          style="display: inline-block;"
                          color="blue"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-square-edit-outline
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title>Eshop items categories</v-card-title>
                        <v-divider />
                        <v-card-text style="height: 400px;">
                          <div v-for="category in itemCategoryOptions" :key="category.id">
                            <div style="display: inline-block; font-size: 16px; height: 30px; margin-top: 15px;">
                              {{ category.category }}
                            </div>
                            <div
                              style="display: inline-block; margin-top: 10px;"
                              class="float-right"
                            >
                              <v-dialog
                                v-model="dialogConfirmRemoveCategory"
                                width="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    style="display: inline-block;"
                                    color="red"
                                    v-bind="attrs"
                                    @click="playSound(category.id)"
                                    v-on="on"
                                  >
                                    mdi-delete
                                  </v-icon>
                                </template>

                                <v-card>
                                  <v-card-title class="text-h5 grey lighten-2">
                                    Removing category
                                  </v-card-title>

                                  <v-card-text class="mt-4 text-center">
                                    <v-icon color="red" style="font-size: 120px;">
                                      mdi-robber
                                    </v-icon><br>
                                    Please consider this action carefully as the lord of the darkness may arise and destroy your dreams!. <br> <br> And it also could break the entire internet in the world.
                                  </v-card-text>

                                  <v-divider />

                                  <v-card-actions>
                                    <v-btn
                                      color="blue"
                                      text
                                      @click="dialogConfirmRemoveCategory = false; playSound('GoodChoice')"
                                    >
                                      Nah I like my dreams
                                    </v-btn>
                                    <v-spacer />
                                    <v-btn
                                      color="red"
                                      text
                                      @click="removeCategory(category.id); dialogConfirmRemoveCategory = false;"
                                    >
                                      I accept and agree
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </div>
                            <v-divider />
                          </div>
                          <div v-if="categoryManipulationMessage" class="mt-1 red--text">
                            {{ categoryManipulationMessage }}
                          </div>
                        </v-card-text>
                        <v-divider />
                        <div class="mt-4" style="margin-bottom: -15px;">
                          <div style="display: inline-block; width: 50%; margin-left: 20px;">
                            <v-text-field
                              v-model="newCategory"
                              placeholder="new category"
                              clearable
                              outlined
                            />
                          </div>
                          <div style="display: inline-block; margin-left: 15px;">
                            <v-btn
                              :disabled="!newCategory.length"
                              color="green"
                              :dark="!newCategory.length ? false : true"
                              @click="addNewCategory"
                            >
                              Add new category
                            </v-btn>
                          </div>
                        </div>
                        <v-divider />
                        <v-card-actions>
                          <v-btn
                            color="red"
                            dark
                            @click="editCategoriesDialog = false"
                          >
                            Close
                          </v-btn>
                          <v-spacer />
                          <v-btn
                            color="green"
                            dark
                            @click="editCategoriesDialog = false"
                          >
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </v-col>
                <div class="mt-6">
                  <v-icon
                    v-if="productId"
                    medium
                    :color="productId ? 'green' : 'grey'"
                  >
                    mdi-check-all
                  </v-icon>
                </div>
                <v-col cols="3">
                  <v-text-field
                    v-model="productId"
                    flat
                    solo
                    style="margin-bottom: -10px;"
                    type="text"
                    disabled
                    dense
                    required
                  />
                </v-col>
              </v-row>
              <v-row class="mb-1" style="margin-top: -30px;">
                <v-col cols="3">
                  <v-text-field
                    v-model="qtyInStock"
                    label="Item Quantity in Stock"
                    type="number"
                    dense
                    outlined
                    required
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card elevation="3" class="mb-6">
            <v-card-title>
              Pricing
            </v-card-title>
            <v-card-text>
              <v-row style="margin-top: -5px; margin-bottom: -30px;">
                <v-col cols="3">
                  <v-checkbox
                    v-model="itemPriceEnabled"
                    :disabled="typeof productPriceId === 'string' ? true : false"
                    color="green"
                    dense
                    label="One Time Payment?"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="itemPrice"
                    label="One time price"
                    :disabled="!itemPriceEnabled || typeof productPriceId === 'string' ? true : false"
                    outlined
                    style="max-width: 250px; display: inline-block;"
                    dense
                    type="number"
                    step="any"
                    required
                  />
                  <div v-if="editing" style="display: inline-block; margin-left: 10px;">
                    <v-icon
                      v-if="productPriceId"
                      color="blue"
                      style="cursor: pointer;"
                      @click="editPrice('itemPrice')"
                    >
                      mdi-square-edit-outline
                    </v-icon>
                    <v-btn
                      v-else
                      color="green"
                      dark
                      dense
                      small
                      @click="saveEditedPrice('itemPrice', itemId)"
                    >
                      <div v-if="btnLoading">
                        <v-progress-circular
                          indeterminate
                          style="max-width: 25px;"
                          color="white"
                        />
                      </div>
                      <div v-else>
                        save
                      </div>
                    </v-btn>
                  </div>
                </v-col>
                <div class="mt-6">
                  <v-icon
                    v-if="productPriceId"
                    medium
                    color="green"
                  >
                    mdi-check-all
                  </v-icon>
                </div>
                <v-col cols="4">
                  <v-text-field
                    v-model="productPriceId"
                    style="margin-bottom: -10px;"
                    type="text"
                    dense
                    flat
                    solo
                    disabled
                    required
                  />
                </v-col>
              </v-row>
              <v-divider class="grey lighten-3" />
              <v-row style="margin-top: -5px; margin-bottom: -30px;">
                <v-col cols="3">
                  <v-checkbox
                    v-model="subscriptionUpfrontEnabled"
                    :disabled="typeof productSubUpfrontPriceId === 'string' ? true : false"
                    color="green"
                    dense
                    label="Subscription upfront"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="subscriptionUpfrontPrice"
                    label="Sub upfront (kr)"
                    :disabled="!subscriptionUpfrontEnabled || typeof productSubUpfrontPriceId === 'string' ? true : false"
                    outlined
                    style="max-width: 250px; display: inline-block;"
                    type="number"
                    dense
                    required
                  />
                  <div v-if="editing" style="display: inline-block; margin-left: 10px;">
                    <v-icon
                      v-if="productSubUpfrontPriceId"
                      color="blue"
                      style="cursor: pointer;"
                      @click="editPrice('subscriptionUpfrontPrice')"
                    >
                      mdi-square-edit-outline
                    </v-icon>
                    <v-btn
                      v-else
                      color="green"
                      dark
                      dense
                      small
                      @click="saveEditedPrice('subscriptionUpfrontPrice', itemId)"
                    >
                      <div v-if="btnLoading">
                        <v-progress-circular
                          indeterminate
                          style="max-width: 25px;"
                          color="white"
                        />
                      </div>
                      <div v-else>
                        save
                      </div>
                    </v-btn>
                  </div>
                </v-col>
                <div class="mt-6">
                  <v-icon
                    v-if="productSubUpfrontPriceId"
                    medium
                    color="green"
                  >
                    mdi-check-all
                  </v-icon>
                </div>
                <v-col cols="4">
                  <v-text-field
                    v-model="productSubUpfrontPriceId"
                    style="margin-bottom: -10px;"
                    type="text"
                    dense
                    flat
                    solo
                    disabled
                    required
                  />
                </v-col>
              </v-row>
              <v-divider class="grey lighten-3" />
              <v-row style="margin-top: -5px; margin-bottom: -30px;">
                <v-col cols="3">
                  <v-checkbox
                    v-model="subscription"
                    color="blue"
                    :disabled="typeof productPriceSubId === 'string' ? true : false"
                    dense
                    label="Subscription price"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="subscriptionPriceMonth"
                    label="Sub (kr)"
                    style="max-width: 250px; display: inline-block;"
                    :disabled="!subscription || typeof productPriceSubId === 'string'"
                    outlined
                    dense
                    type="number"
                    required
                  />
                  <div v-if="editing" style="display: inline-block; margin-left: 10px;">
                    <v-icon
                      v-if="productPriceSubId"
                      color="blue"
                      style="cursor: pointer;"
                      @click="editPrice('subscriptionPriceMonth')"
                    >
                      mdi-square-edit-outline
                    </v-icon>
                    <v-btn
                      v-else
                      color="green"
                      dark
                      dense
                      small
                      @click="saveEditedPrice('subscriptionPriceMonth', itemId)"
                    >
                      <div v-if="btnLoading">
                        <v-progress-circular
                          indeterminate
                          style="max-width: 25px;"
                          color="white"
                        />
                      </div>
                      <div v-else>
                        save
                      </div>
                    </v-btn>
                  </div>
                </v-col>
                <div class="mt-5">
                  <v-icon
                    v-if="productPriceSubId"
                    medium
                    color="green"
                  >
                    mdi-check-all
                  </v-icon>
                </div>
                <v-col cols="4">
                  <v-text-field
                    v-model="productPriceSubId"
                    style="margin-bottom: -10px;"
                    type="text"
                    dense
                    flat
                    solo
                    disabled
                    required
                  />
                </v-col>
              </v-row>
              <v-divider class="grey lighten-3" />
              <v-row style="margin-top: -5px; margin-bottom: -30px;">
                <v-col cols="3">
                  <v-checkbox
                    v-model="memberSubscriptionEnabled"
                    :disabled="typeof memberSubscriptionPriceId === 'string' ? true : false"
                    color="blue"
                    dense
                    label="Member Subscription price"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="memberSubscriptionPriceMonth"
                    label="Member Sub (kr)"
                    :disabled="!memberSubscriptionEnabled || typeof memberSubscriptionPriceId === 'string'"
                    style="max-width: 250px; display: inline-block;"
                    outlined
                    dense
                    type="number"
                    required
                  />
                  <div v-if="editing" style="display: inline-block; margin-left: 10px;">
                    <v-icon
                      v-if="memberSubscriptionPriceId"
                      color="blue"
                      style="cursor: pointer;"
                      @click="editPrice('memberSubscriptionPriceMonth')"
                    >
                      mdi-square-edit-outline
                    </v-icon>
                    <v-btn
                      v-else
                      color="green"
                      dark
                      dense
                      small
                      @click="saveEditedPrice('memberSubscriptionPriceMonth', itemId)"
                    >
                      <div v-if="btnLoading">
                        <v-progress-circular
                          indeterminate
                          style="max-width: 25px;"
                          color="white"
                        />
                      </div>
                      <div v-else>
                        save
                      </div>
                    </v-btn>
                  </div>
                </v-col>
                <div class="mt-5">
                  <v-icon
                    v-if="memberSubscriptionPriceId"
                    medium
                    color="green"
                  >
                    mdi-check-all
                  </v-icon>
                </div>
                <v-col cols="4">
                  <v-text-field
                    v-model="memberSubscriptionPriceId"
                    type="text"
                    dense
                    flat
                    solo
                    disabled
                    required
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card elevation="3" class="mb-6">
            <v-card-title>
              Pictures
            </v-card-title>
            <v-card-text>
              <!-- <v-row class="mt-0">
                <v-col cols="6">
                  <v-text-field
                    v-model="itemPicture"
                    label="Item Picture (http link)"
                    outlined
                    required
                  />
                </v-col>
              </v-row> -->
              <v-row
                align="center"
                class="mt-0 mb-2"
              >
                <v-col
                  v-for="item in itemPicturesAWSLinks"
                  :key="item"
                  cols="1"
                  style=""
                >
                  <v-img :src="item" />
                  <v-icon
                    style="position: absolute; top: 60px; pointer-events: cursor;"
                    color="red"
                    @click="removePicture(item)"
                  >
                    mdi-close
                  </v-icon>
                </v-col>
                <div v-if="!itemPicturesAWSLinks.length" class="ml-4 mb-5">
                  No Pictures selected
                </div>
              </v-row>
              <v-row class="mb-1 ml-1">
                <v-dialog
                  v-model="openPictureSelectDialog"
                  scrollable
                  max-width="1200px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-3"
                      dense
                      color="green"
                      v-bind="attrs"
                      dark
                      v-on="on"
                    >
                      Select Pictures
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>Select eshop item pictures</v-card-title>
                    <v-divider />
                    <v-card-text style="height: 900px;">
                      <v-text-field v-model="searchPicture" placeholder="Search" />
                      <div
                        v-for="item in itemShopPicturesFiltered"
                        :key="item.id"
                        style="display: inline-block;"
                      >
                        <div
                          :class="isPictureSelected(item) ? 'selectedPicture': ''"
                          style="margin: 5px; padding: 10px;"
                          @click="selectPictureForProduct(item)"
                        >
                          <v-img style="max-width: 250px;" :src="item.location" />
                          <div style="text-align: center;">
                            {{ item.name }}
                          </div>
                        </div>
                      </div>
                    </v-card-text>

                    <v-divider />
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="blue"
                        dark
                        @click="openPictureSelectDialog = false; searchPicture = '';"
                      >
                        Done
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog
                  v-model="openPictureUploadDialog"
                  scrollable
                  max-width="600px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-3"
                      dense
                      color="blue"
                      v-bind="attrs"
                      dark
                      v-on="on"
                    >
                      Upload new Pictures
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>Eshop items categories</v-card-title>
                    <v-divider />
                    <v-card-text style="height: 400px;">
                      <div v-if="!uploadError && !uploadLoading">
                        <v-file-input
                          v-model="fileToUpload"
                          multiple
                          accept="image/png, image/jpeg, image/bmp"
                          :rules="fileRules"
                        />
                        <div v-for="file in fileToUpload" :key="file.name">
                          {{ file.name }}
                          <v-icon
                            style="cursor: pointer;"
                            small
                            color="red"
                            @click="removeFileFromUpload(file.name)"
                          >
                            mdi-delete
                          </v-icon>
                        </div>
                        <v-row>
                          <v-spacer />
                          <v-btn
                            v-if="fileToUpload"
                            color="green"
                            dark
                            class="mt-3"
                            @click="uploadPictures"
                          >
                            Upload
                          </v-btn>
                        </v-row>
                      </div>
                      <div
                        v-if="uploadLoading"
                        style="text-align: center"
                        class="mt-8"
                      >
                        <v-progress-circular
                          indeterminate
                          color="green"
                        />
                      </div>
                      <div
                        v-if="uploadError"
                        style="text-align: center"
                        class="mt-8"
                      >
                        Something has failed...<br>
                        <v-btn
                          color="green"
                          dark
                          class="mt-5"
                          @click="uploadError = false; fileToUpload = null"
                        >
                          Try Again
                        </v-btn>
                      </div>
                      <div
                        v-if="uploadSuccess"
                        style="text-align: center; font-size: 20px;"
                        class="mt-8 green--text"
                      >
                        Upload success!
                      </div>
                    </v-card-text>

                    <v-divider />
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="blue"
                        dark
                        @click="openPictureUploadDialog = false"
                      >
                        Done
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card elevation="3" class="mb-6">
            <v-card-title>
              Item Description
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="itemShortDescription"
                class="mt-4"
                label="Item Short Description"
                :rules="itemShortDescriptionRules"
                :counter="100"
                outlined
                required
              />
              <!-- <VueEditor
            v-model="itemDescription"
            color="primary"
            :rules="itemDescriptionRules"
          /> -->
              <div style="height:45px;" class="ml-3 mt-4">
                <v-row>
                  <v-btn
                    small
                    class="mr-1"
                    :color="descriptionSelector === 'itemDescription' ? 'green' : 'grey'"
                    dark
                    @click="descriptionSelector = 'itemDescription'"
                  >
                    Item Description
                  </v-btn>
                  <v-btn
                    class="mr-1"
                    :color="descriptionSelector === 'itemFeatures' ? 'green' : 'grey'"
                    dark
                    small
                    @click="descriptionSelector = 'itemFeatures'"
                  >
                    Item Features
                  </v-btn>
                  <v-btn
                    class="mr-1"
                    small
                    :color="descriptionSelector === 'itemSpecs' ? 'green' : 'grey'"
                    dark
                    @click="descriptionSelector = 'itemSpecs'"
                  >
                    Item Specs
                  </v-btn>
                </v-row>
              </div>
              <div v-if="descriptionSelector === 'itemDescription'" class="editor">
                <h4>{{ descriptionSelector.split('item')[1] }}</h4>
                <ckeditor
                  v-model="itemDescription"
                  :editor="editor"
                  :config="editorConfig"
                />
                <div :class="itemDescription.length > 1000 ? 'red--text' : 'green--text'">
                  {{ itemDescription.length }} / 400 Recomended
                </div>
              </div>
              <div v-else-if="descriptionSelector === 'itemFeatures'">
                <h4>{{ descriptionSelector.split('item')[1] }}</h4>
                <ckeditor
                  v-model="itemFeatures"
                  :editor="editor"
                  :config="editorConfig"
                />
                <div :class="itemFeatures.length > 1000 ? 'red--text' : 'green--text'">
                  {{ itemFeatures.length }} / 1000 Recomended
                </div>
              </div>
              <div v-else-if="descriptionSelector === 'itemSpecs'">
                <h4>{{ descriptionSelector.split('item')[1] }}</h4>
                <ckeditor
                  v-model="itemSpecs"
                  :editor="editor"
                  :config="editorConfig"
                />
                <div :class="itemSpecs.length > 1000 ? 'red--text' : 'green--text'">
                  {{ itemSpecs.length }} / 1000 Recomended
                </div>
              </div>
            </v-card-text>
          </v-card>
          <div class="mb-6">
            <v-btn
              v-if="!editing"
              color="success"
              class="mr-4 mt-4"
              :disabled="!itemName || !itemPicturesAWSLinks.length || btnLoading"
              @click="addEshopItem"
            >
              <div v-if="btnLoading">
                <v-progress-circular
                  indeterminate
                  color="white"
                />
              </div>
              <div v-else>
                Create Eshop item
              </div>
            </v-btn>
            <v-btn
              v-else
              color="success"
              class="mr-4 mt-4"
              :disabled="!itemName || !itemPicturesAWSLinks.length || btnLoading"
              @click="editNonPriceItemsSave"
            >
              <div v-if="btnLoading">
                <v-progress-circular
                  indeterminate
                  color="white"
                />
              </div>
              <div v-else>
                Save item
              </div>
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-slide-x-transition>
  </v-container>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import sound from '../assets/ImComingForYou.mp3'
// import sound2 from '../assets/GoodChoice.mp3'
import draggable from 'vuedraggable'

export default {
  name: 'Users',
  components: {
    draggable
  },
  data () {
    return {
      selectedView: '1',
      descriptionSelector: 'itemDescription',
      deleteItemId: 0,
      editCategoriesDialog: false,
      error: '',
      newCategory: '',
      dialogConfirmRemoveCategory: false,
      dialogDelete: false,
      openPictureUploadDialog: false,
      openPictureSelectDialog: false,
      showProgress: true,
      btnLoading: false,
      pullingData: false,
      editing: false,
      itemEnabled: true,
      itemId: null,
      itemName: '',
      itemSKU: '',
      qtyInStock: 0,
      itemPicture: '',
      itemPicturesAWSLinks: [],
      itemPriceEnabled: false,
      itemPrice: null,
      subscriptionUpfrontPrice: null,
      subscriptionUpfrontEnabled: false,
      subscription: false,
      subscriptionPriceMonth: null,
      memberSubscriptionEnabled: false,
      memberSubscriptionPriceMonth: null,
      itemShortDescription: '',
      itemDescription: '',
      itemFeatures: '',
      itemSpecs: '',
      itemLink: '',
      itemCategory: '',
      itemCategoryOptions: [{ id: 1, category: 'test2' }, { id: 2, category: 'test3' }, { id: 3, category: 'test4' }, { id: 4, category: 'test5' }],
      itemShopPictures: [],
      searchPicture: '',
      productId: null,
      productPriceId: null,
      productPriceSubId: null,
      productSubUpfrontPriceId: null,
      memberSubscriptionPriceId: null,
      pagination: {},
      search: '',
      items: [],
      sortBy: 'index',
      sortDesc: false,
      headers: [
        { text: '#', value: 'id' },
        { text: 'index', value: 'index' },
        { text: 'Enabled', value: 'itemEnabled' },
        { text: 'Item SKU', value: 'itemSKU' },
        { text: 'Item Name', value: 'itemName' },
        { text: 'One Time Price', value: 'itemPrice' },
        { text: 'Sub Upfront', value: 'subscriptionUpfrontPrice' },
        { text: 'Sub Monthly', value: 'subscriptionPriceMonth' },
        { text: 'Member Sub Monthly', value: 'memberSubscriptionPriceMonth' },
        { text: 'Item Image', value: 'itemPicture' },
        { text: 'QTY in Stock', value: 'qtyInStock' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      footerProps: {
        'items-per-page-options': [20, 30, 50, 100]
      },
      itemShortDescriptionRules: [
        (v) => !!v || 'itemShortDescription is required',
        (v) =>
          (v && v.length <= 100) ||
          'itemDescription must be less than 100 characters'
      ],
      categoryManipulationMessage: null,
      fileRules: [
        files => !files || !files.some(file => file.size > 2e6) || 'Avatar size should be less than 2 MB!'
      ],
      fileToUpload: null,
      loaded: false,
      uploadError: false,
      uploadLoading: false,
      uploadSuccess: false,
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        height: 100
      },
      isMounted: false,
      errorMessage: 'adasdasd',
      showError: false,
      eshopQTYs: []
    }
  },
  computed: {
    itemShopPicturesFiltered () {
      const pics = this.itemShopPictures
      return pics.filter(item => {
        return item.name.toLowerCase().includes(this.searchPicture.toLowerCase())
      })
    }
  },
  watch: {
    subscriptionUpfrontEnabled: function (val) {
      if (val) {
        this.subscription = true
      }
    }
  },
  mounted () {
    this.pullEshopQtys()
    this.pullEshopCategoriesAndPictures()
    this.pullEshopData()
    this.isMounted = true
  },
  methods: {
    pullEshopData () {
      this.showProgress = true
      this.$store.dispatch('pullEshopData')
        .then(resp => {
          if (resp) {
            const items = resp.data
            this.loaded = false
            setTimeout(() => {
              this.eshopQTYs.forEach(item => {
                const index = items.findIndex(i => i.itemSKU === item.itemSKU)
                items[index].qtyInStock = item.qtyInStock
                this.items = items
                this.loaded = true
              }, 500)
            })
            this.showProgress = false
          }
          this.showProgress = false
        })
    },
    pullEshopCategoriesAndPictures () {
      this.$store.dispatch('pullEshopCategoriesAndPictures')
        .then(resp => {
          this.itemCategoryOptions = resp.data.categories
          this.itemShopPictures = resp.data.pictures
        })
    },
    pullEshopQtys () {
      this.$store.dispatch('pullEshopQTYs').then(resp => {
        this.eshopQTYs = resp.data
      })
    },
    addEshopItem () {
      let newItemIndex = Math.max.apply(Math, this.items.map(function (i) { return i.index })) + 1
      if (!newItemIndex) {
        newItemIndex = 0
      }
      this.btnLoading = true
      this.$store.dispatch('addEshopItem', {
        itemEnabled: this.itemEnabled,
        itemName: this.itemName,
        itemSKU: this.itemSKU,
        itemShortDescription: this.itemShortDescription,
        itemPicture: this.itemPicture,
        itemPicturesAWSLinks: this.itemPicturesAWSLinks,
        itemDescription: this.itemDescription,
        itemFeatures: this.itemFeatures,
        itemSpecs: this.itemSpecs,
        itemCategory: this.itemCategory,
        itemPriceEnabled: this.itemPriceEnabled,
        itemPrice: this.itemPrice,
        subscriptionUpfrontEnabled: this.subscriptionUpfrontEnabled,
        subscriptionUpfrontPrice: this.subscriptionUpfrontPrice,
        subscription: this.subscription,
        subscriptionPriceMonth: this.subscriptionPriceMonth,
        memberSubscriptionEnabled: this.memberSubscriptionEnabled,
        memberSubscriptionPriceMonth: this.memberSubscriptionPriceMonth,
        qtyInStock: this.qtyInStock,
        index: newItemIndex
      }).then(() => {
        this.btnLoading = false
        this.pullEshopQtys()
        this.pullEshopCategoriesAndPictures()
        this.pullEshopData()
        this.selectedView = '1'
        this.clearForm()
      })
    },
    editItem (item) {
      this.editing = true
      this.itemEnabled = item.itemEnabled
      this.itemId = item.id
      this.itemName = item.itemName
      this.itemPrice = item.itemPrice
      this.itemSKU = item.itemSKU
      this.qtyInStock = this.eshopQTYs.filter(i => i.itemSKU === item.itemSKU)[0].qtyInStock
      this.itemShortDescription = item.itemShortDescription
      this.itemPicturesAWSLinks = item.itemPicturesAWSLinks
      this.itemPicture = item.itemPicture
      this.itemDescription = item.itemDescription
      this.itemFeatures = item.itemFeatures
      this.itemSpecs = item.itemSpecs
      this.itemCategory = item.itemCategory
      this.itemLink = item.itemLink
      this.subscription = item.subscription
      this.subscriptionPriceMonth = item.subscriptionPriceMonth
      this.productId = item.productId ? item.productId : null
      this.productPriceId = item.productPriceId ? item.productPriceId : null
      this.productPriceSubId = item.productPriceSubId ? item.productPriceSubId : null
      this.itemPriceEnabled = item.itemPriceEnabled ? item.itemPriceEnabled : null
      this.subscriptionUpfrontEnabled = item.subscriptionUpfrontEnabled
      this.subscriptionUpfrontPrice = item.subscriptionUpfrontPrice
      this.productSubUpfrontPriceId = item.productSubUpfrontPriceId
      this.memberSubscriptionPriceId = item.memberSubscriptionPriceId
      this.memberSubscriptionEnabled = item.memberSubscriptionEnabled
      this.memberSubscriptionPriceMonth = item.memberSubscriptionPriceMonth
      this.selectedView = '2'
    },
    editPrice (which) {
      if (which === 'subscriptionPriceMonth') {
        this.productPriceSubId = null
      } else if (which === 'memberSubscriptionPriceMonth') {
        this.memberSubscriptionPriceId = null
      } else if (which === 'subscriptionUpfrontPrice') {
        this.productSubUpfrontPriceId = null
      } else if (which === 'itemPrice') {
        this.productPriceId = null
      }
    },
    saveEditedPrice (which, itemId) {
      this.btnLoading = true
      if (this.subscription === false) this.subscriptionPriceMonth = null
      if (this.subscriptionUpfrontEnabled === false) this.subscriptionUpfront = null
      if (this.homevisionPlusDiscountEnabled === false) this.homevisionPlusDiscountPrice = null
      if (this.itemPriceEnabled === false) this.itemPrice = null

      let type = ''
      let enabled = false
      let newPrice = 0

      if (which === 'subscriptionPriceMonth') {
        type = 'subscriptionPriceMonth'
        enabled = this.subscription
        newPrice = this.subscriptionPriceMonth
      } else if (which === 'memberSubscriptionPriceMonth') {
        type = 'memberSubscriptionPriceMonth'
        enabled = this.memberSubscriptionEnabled
        newPrice = this.memberSubscriptionPriceMonth
      } else if (which === 'subscriptionUpfrontPrice') {
        type = 'subscriptionUpfrontPrice'
        enabled = this.subscriptionUpfrontEnabled
        newPrice = this.subscriptionUpfrontPrice
      } else if (which === 'itemPrice') {
        type = 'itemPrice'
        enabled = this.itemPriceEnabled
        newPrice = this.itemPrice
      }

      this.$store.dispatch('editEshopItemPrice', { itemId, type, enabled, newPrice })
        .then(() => {
          this.pullEshopData()
          this.selectedView = '1'
          this.btnLoading = false
        })
        .catch((error) => {
          this.errorMessage = 'Something has failed! try again. ' + error
          this.showError = true
          this.btnLoading = false
          setTimeout(() => {
            this.showError = false
            this.errorMessage = ''
          }, 2000)
        })
    },
    editNonPriceItemsSave () {
      this.btnLoading = true
      if (this.subscription === false) this.subscriptionPriceMonth = null
      if (this.subscriptionUpfrontEnabled === false) this.subscriptionUpfront = null
      if (this.homevisionPlusDiscountEnabled === false) this.homevisionPlusDiscountPrice = null
      if (this.itemPriceEnabled === false) this.itemPrice = null

      this.$store.dispatch('editEshopItemNonPrice', {
        itemId: this.itemId,
        itemEnabled: this.itemEnabled,
        itemName: this.itemName,
        itemSKU: this.itemSKU,
        itemShortDescription: this.itemShortDescription,
        itemFeatures: this.itemFeatures,
        itemSpecs: this.itemSpecs,
        itemPicturesAWSLinks: this.itemPicturesAWSLinks,
        itemCategory: this.itemCategory,
        itemDescription: this.itemDescription,
        qtyInStock: this.qtyInStock
      }).then(resp => {
        this.btnLoading = false
        this.pullEshopQtys()
        this.pullEshopCategoriesAndPictures()
        this.pullEshopData()
        this.selectedView = '1'
        this.clearForm()
      })
    },
    closeDelete () {
      this.dialogDelete = false
    },
    deleteDialog (item) {
      this.dialogDelete = true
      this.deleteItemId = item.id
    },
    deleteItem () {
      const maxItemOderIndex = Math.max.apply(Math, this.items.map(function (i) { return i.index }))
      const deleteItem = this.items.find(item => item.id === this.deleteItemId)
      const orderIndex = deleteItem.index

      const indexOfItemToDelete = this.items.findIndex(item => item.id === this.deleteItemId)
      this.items.splice(indexOfItemToDelete, 1)

      if (maxItemOderIndex !== orderIndex) {
        this.items.forEach(item => {
          if (item.index > orderIndex) {
            item.index--
          }
        })
      }

      this.$store.dispatch('deleteEshopItem', { itemId: this.deleteItemId, newItems: this.items }).then(() => {
        this.selectedView = '1'
        this.clearForm()
        this.pullEshopData()
        this.closeDelete()
      })
    },
    clearForm () {
      this.editing = false
      this.itemId = ''
      this.itemEnabled = true
      this.itemLink = ''
      this.itemDescription = ''
      this.itemPicture = ''
      this.itemSKU = null
      this.itemShortDescription = ''
      this.itemName = ''
      this.itemLink = ''
      this.qtyInStock = 0
      this.itemCategory = ''
      this.itemPrice = null
      this.deleteItemId = null
      this.productId = null
      this.productPriceId = null
      this.productPriceSubId = null
      this.itemFeatures = ''
      this.itemSpecs = ''
      this.subscription = false
      this.subscriptionPriceMonth = null
      this.itemPriceEnabled = false
      this.subscriptionUpfrontEnabled = false
      this.subscriptionUpfrontPrice = null
      this.memberSubscriptionPriceId = null
      this.memberSubscriptionEnabled = null
      this.memberSubscriptionPriceMonth = null
      this.productSubUpfrontPriceId = null
      this.homevisionPlusPriceId = null
      this.itemPicturesAWSLinks = []
    },
    trimText (text) {
      const length = 190
      if (text) {
        return text.length > length ? text.substring(0, length) + '........' : text
      } else {
        return ''
      }
    },
    removeCategory (id) {
      const index = this.itemCategoryOptions.findIndex(item => item.id === id)
      this.$store.dispatch('removeEshopCategory', id)
        .then((resp) => {
          this.itemCategoryOptions.splice(index, 1)
        })
        .catch((error) => {
          this.categoryManipulationMessage = error
        })
    },
    addNewCategory () {
      const category = this.newCategory
      if (category) {
        this.$store.dispatch('addEshopCategory', { newCategory: category })
          .then((resp) => {
            const id = resp.data.id
            const object = {
              id,
              category
            }
            this.itemCategoryOptions.push(object)
            this.newCategory = ''
          })
          .catch((error) => {
            this.categoryManipulationMessage = error
          })
      }
    },
    playSound (which) {
      // let soundToPlay
      // if (which === 'ImComingForYou') {
      //   soundToPlay = sound
      // } else if (which === 'GoodChoice') {
      //   soundToPlay = sound2
      // }
      // soundToPlay = null
      // const note = new Audio(soundToPlay)
      // note.play()
      // setTimeout(() => {
      //   note.stop()
      // }, 2000)
    },
    removeFileFromUpload (fileName) {
      const index = this.fileToUpload.findIndex(item => item.name === fileName)
      this.fileToUpload.splice(index, 1)
    },
    uploadPictures () {
      this.uploadLoading = true
      const fileData = new FormData()

      for (const file of this.fileToUpload) {
        fileData.append('images', file)
      }

      this.$store.dispatch('uploadShopPictures', fileData)
        .then((resp) => {
          this.uploadLoading = false
          this.fileToUpload = null
          this.uploadSuccess = true
          this.pullEshopCategoriesAndPictures()
          setTimeout(() => {
            this.uploadSuccess = false
          }, 1500)
        })
        .catch((error) => {
          this.uploadLoading = false
          this.uploadError = true
          this.error = error
        })
    },
    selectPictureForProduct (item) {
      const index = this.itemPicturesAWSLinks.findIndex(i => i === item.location)

      if (index === -1) {
        this.itemPicturesAWSLinks.push(item.location)
      } else {
        this.itemPicturesAWSLinks.splice(index, 1)
      }
    },
    removePicture (item) {
      const index = this.itemPicturesAWSLinks.findIndex(i => i === item)
      this.itemPicturesAWSLinks.splice(index, 1)
    },
    isPictureSelected (item) {
      const index = this.itemPicturesAWSLinks.findIndex(i => i === item.location)
      let result = false
      if (index !== -1) result = true
      return result
    },
    endedSort (event) {
      const indexOldItem = this.items.findIndex(item => item.index === event.moved.oldIndex)
      const oldItem = this.items.splice(indexOldItem, 1)[0]
      oldItem.index = event.moved.newIndex

      const indexNewItem = this.items.findIndex(item => item.index === event.moved.newIndex)
      const newItem = this.items.splice(indexNewItem, 1)[0]
      newItem.index = event.moved.oldIndex

      this.$store.dispatch('reorderItemsInEshop', { oldItem, newItem })

      this.items.push(oldItem, newItem)
    }
  }
}
</script>

<style scoped>
.selectedPicture {
  box-shadow:inset 0px 0px 0px 10px #f00;
}

</style>
